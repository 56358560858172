import React from 'react'
import styled from 'styled-components';

const Container = styled.footer`

`;

type Props = {}

const Footer = (props: Props) => {
  return (
	<Container>Footer</Container>
  )
}

export default Footer